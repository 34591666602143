import { StaticImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import React from "react"
import DownloadIcon from "../../../assets/svgs/download-icon-dark.svg"
import DownloadLink from "../downloadLink/DownloadLink"
import { Card, CardContent } from "./Card"
import { DownloadButton } from "./DownloadButton"
import { Layout } from "./Layout"
import { Paragraph } from "./Paragraph"
import { Title } from "./Title"

export const FindingRightConsultant = ({ dark = false }) => {
  const downloadFileName =
    "Rebase - Mitä sinun tulee tietää IT-asiantuntijan hankinnasta.pdf"
  return (
    <Layout dark={dark}>
      <Card>
        <StaticImage
          src="../../../assets/images/booklet.png"
          alt="Finding the right IT consultant"
          style={{ aspectRatio: "1.05", maxWidth: "400px" }}
        />
        <CardContent>
          <Title>Finding the right IT consultant</Title>
          <Paragraph>
            Our guide contains everything you wanted to know about outsourcing
            an IT expert (but were afraid to ask)
          </Paragraph>
          <DownloadLink file={downloadFileName}>
            <div style={{ display: "flex", cursor: "pointer" }}>
              <DownloadIcon />
              <DownloadButton>Download (Finnish only)</DownloadButton>
            </div>
          </DownloadLink>
        </CardContent>
      </Card>
    </Layout>
  )
}

FindingRightConsultant.propTypes = {
  dark: PropTypes.bool,
}
